import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  List,
  Table,
  message,
  Tag,
  Space,
  Button,
  Modal,
  DatePicker,
} from "antd";
import { Bar, Pie } from "react-chartjs-2";
import { useNavigate, useParams } from "react-router-dom";
import "chart.js/auto"; // for Chart.js
import "./styles/UserDetail.css";
import apiService from "../services/apiService";
import { formatTimestamp } from "../utils/formatTimes";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { setDeviceId, setUserName } from "../store/deviceSlice";
const GET_USER_DETAIL = "/user/get-full-info";
const LIST_PACKAGE_HISTORY = (id) => `/user/get-history/${id}/approve`;
const UserDetail = () => {
  const { user_id } = useParams();
  const [user, setUser] = useState(null);
  const [pieData, setPieData] = useState(null);
  const [barData, setBarData] = useState(null);
  const [machineData, setMachineData] = useState(null);
  const [pakageHistory, setpakageHistory] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const handleOpenModal = (record) => {
    setSelectedRecord(record);
    setSelectedDate(null); // Reset selected date
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleConfirm = async () => {
    if (!selectedDate) {
      message.warning("Please select a date!");
      return;
    }
    console.log(selectedDate.toISOString());
    console.log(selectedRecord.activate_codes_id);
    const payload = {
      activate_code_id: selectedRecord.activate_codes_id,
      end_package_at: selectedDate.toISOString(),
    };

    try {
      await apiService.put("/account/update-time", payload); // Call your API
      message.success("Expiration date updated successfully!");
      setIsModalVisible(false);

      // Re-fetch the data to reflect changes
      const response = await apiService.get(GET_USER_DETAIL, {
        params: {
          skip: 0,
          limit: 10,
          user_id: user_id,
        },
      });
      setMachineData(response.data.info); // Update the machine data
    } catch (error) {
      message.error("Failed to update expiration date");
    }
  };
  useEffect(() => {
    // Fetch user details using the username
    const fetchUserDetails = async () => {
      // Replace with actual API call

      try {
        const response = await apiService.get(GET_USER_DETAIL, {
          params: {
            skip: 0,
            limit: 10,
            user_id: user_id,
          },
        });
        const responseHistory = await apiService.get(
          LIST_PACKAGE_HISTORY(user_id),
          {
            params: {
              skip: 0,
              limit: 10,
            },
          }
        );

        console.log(responseHistory.data.info);
        setpakageHistory(responseHistory.data.info);
        // Assuming the response structure has an `items` array
        const userInfor = {
          fullname: response.data.user.fullname,
          phoneNumber: response.data.user.phonenumber,
          email: response.data.user.email,
          industry: response.data.user.major[0],
          created_at: formatTimestamp(response.data.user.created_at),
        };
        setUser(userInfor);

        setMachineData(response.data.info);
        console.log(response.data.info);
        setBarData({
          labels: ["Day 1", "Day 2", "Day 3", "Day 4", "Day 5"],
          datasets: [
            {
              label: "Đóng gói",
              backgroundColor: "#36A2EB",
              data: [3, 2, 2, 1, 2],
            },
            {
              label: "Trả hàng",
              backgroundColor: "#FF6384",
              data: [1, 0, 1, 1, 2],
            },
          ],
        });
        setPieData({
          labels: ["Đóng gói", "Trả hàng"],
          datasets: [
            {
              data: [10, 5],
              backgroundColor: ["#36A2EB", "#FF6384"],
            },
          ],
        });
        setBarData({
          labels: ["Day 1", "Day 2", "Day 3", "Day 4", "Day 5"],
          datasets: [
            {
              label: "Đóng gói",
              backgroundColor: "#36A2EB",
              data: [3, 2, 2, 1, 2],
            },
            {
              label: "Trả hàng",
              backgroundColor: "#FF6384",
              data: [1, 0, 1, 1, 2],
            },
          ],
        });
      } catch (error) {
        message.error("Failed to fetch data");
      }

      //const userDetails = await fetch(`/api/users/${username}`).then(res => res.json());
      //setUser(userDetails);

      // Mock data for pie chart
      // const user = {
      //   fullname: "John Doe",
      //   phoneNumber: "123-456-7890",
      //   email: "johndoe@example.com",
      //   industry: "Technology",
      // };
      // setUser(user);
      // setPieData({
      //   labels: ["Đóng gói", "Trả hàng"],
      //   datasets: [
      //     {
      //       data: [10, 5],
      //       backgroundColor: ["#36A2EB", "#FF6384"],
      //     },
      //   ],
      // });

      // // Mock data for bar chart
      // setBarData({
      //   labels: ["Day 1", "Day 2", "Day 3", "Day 4", "Day 5"],
      //   datasets: [
      //     {
      //       label: "Đóng gói",
      //       backgroundColor: "#36A2EB",
      //       data: [3, 2, 2, 1, 2],
      //     },
      //     {
      //       label: "Trả hàng",
      //       backgroundColor: "#FF6384",
      //       data: [1, 0, 1, 1, 2],
      //     },
      //   ],
      // });

      // // Mock machine data
      // setMachineData([
      //   {
      //     id: "M001",
      //     userName: user.fullname,
      //     expireDate: "2024-12-31",
      //   },
      //   { id: "M002", userName: "Jane Smith", expireDate: "2024-11-30" },
      //   { id: "M003", userName: "Alice Johnson", expireDate: "2024-10-29" },
      // ]);
    };
    console.log(user_id);
    fetchUserDetails();
  }, [user_id]);
  const machineColumns = [
    {
      title: "Device ID",
      dataIndex: "device_id",
      key: "device_id",
      render: (device_id) => {
        // Split the device_id string by colon
        const parts = device_id.split(":");

        // Extract the number from the second element (index 1)
        const number = parseInt(parts[1], 10);

        // Increment the number by 1
        const incrementedNumber = number + 1;

        // Return the formatted string
        return `Máy ${incrementedNumber}`;
      },
    },

    {
      title: "Tên gói",
      dataIndex: ["package_info", "description"],
      key: "package_description",
      render: (text, record) => record.package_info.description,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Tag
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          color={status === "online" ? "green" : "red"}
        >
          {status}
        </Tag>
      ),
    },
    {
      title: "Start Package At",
      dataIndex: "start",
      key: "start",
      render: (start) => formatTimestamp(start),
    },
    {
      title: "End Package At",
      dataIndex: "end",
      key: "end",
      render: (end) => formatTimestamp(end),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button icon={<EyeOutlined />} onClick={() => handleView(record)}>
            View
          </Button>
          <Button
            icon={<EditOutlined />}
            onClick={() => handleOpenModal(record)}
          >
            Tùy Chỉnh Ngày Hết hạn
          </Button>
        </Space>
      ),
    },
  ];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleView = (record) => {
    console.log("View:", record);
    // Add your view logic here
    dispatch(setUserName(user.fullname));
    dispatch(setDeviceId(record.device_id));
    navigate(`/view/${user_id}/${record.device_id}`);
  };
  const packageHistoryColumns = [
    {
      title: "Device ID",
      dataIndex: "device_id",
      key: "device_id",
      render: (device_id) => {
        // Split the device_id string by colon
        const parts = device_id.split(":");

        // Extract the number from the second element (index 1)
        const number = parseInt(parts[1], 10);

        // Increment the number by 1
        const incrementedNumber = number + 1;

        // Return the formatted string
        return `Máy ${incrementedNumber}`;
      },
    },

    {
      title: "Tên gói",
      dataIndex: ["package", "description"],
      key: "package_description",
      render: (text, record) => record.package.description,
    },

    {
      title: "Start Package At",
      dataIndex: "start",
      key: "start",
      render: (start) => formatTimestamp(start),
    },
    {
      title: "End Package At",
      dataIndex: "end",
      key: "end",
      render: (end) => formatTimestamp(end),
    },
  ];
  if (!user || !pieData || !barData || !machineData || !pakageHistory) {
    return <div>Loading...</div>;
  }

  return (
    <div className="userdetail" style={{ padding: 24 }}>
      <div className="title">
        <strong>{user.fullname}</strong>
      </div>
      <Row gutter={[16, 16]} className="first-row">
        <Col span={8} className="user_infor">
          <Card title="User Information">
            <div style={{ height: "100%", overflow: "auto" }}>
              <p>Full Name: {user.fullname}</p>
              <p>Phone Number: {user.phoneNumber}</p>
              <p>Email: {user.email}</p>
              <p>Industry: {user.industry}</p>
              <p>Ngày tạo tài khoản: {user.created_at}</p>
            </div>
          </Card>
        </Col>
        <Col span={16} className="machine_id">
          <Card title="List of Machine IDs">
            <Table
              columns={machineColumns}
              dataSource={machineData}
              pagination={false}
              rowKey="id"
            />
          </Card>
        </Col>
        <Col span={24}>
          <Card title="Lịch sử mua gói">
            <Table
              columns={packageHistoryColumns}
              dataSource={pakageHistory}
              pagination={false}
              rowKey="id"
            />
          </Card>
        </Col>
      </Row>
      <Row gutter={[16, 16]}></Row>
      <Modal
        title="Tùy chỉnh ngày hết hạn"
        visible={isModalVisible}
        onCancel={handleCloseModal}
        onOk={handleConfirm}
      >
        <DatePicker
          onChange={handleDateChange}
          showTime
          style={{ width: "100%" }}
        />
      </Modal>
    </div>
  );
};

export default UserDetail;
