import React, { useEffect, useState } from "react";
import { Card, Row, Col, Statistic, Table, message, Button } from "antd";
import apiService from "../services/apiService";
import { useNavigate } from "react-router-dom";

const GET_TOTAL_USER = "/statistic/count-user";
const GET_TOTAL_ONLINE = "/statistic/count-online";
const GET_MAJOR = "/statistic/get-major";
const GET_ONLINE = "/user/get-onoff/online";

const Dashboard = () => {
  const [totalUsers, setTotalUsers] = useState(0);
  const [activeUsers, setActiveUsers] = useState(0);
  const [mostBusiness, setMostBusiness] = useState([]);
  const [userOnline, setuserOnline] = useState([]);
  const navigate = useNavigate();

  const handleView = (user_id) => {
    console.log("View:", user_id);
    // Navigate to the view page
    navigate(`/view/${user_id}`);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiService.get(GET_TOTAL_USER);
        setTotalUsers(response.data);
      } catch (error) {
        message.error("Failed to fetch total users data");
      }
      try {
        const response = await apiService.get(GET_TOTAL_ONLINE);
        setActiveUsers(response.data);
      } catch (error) {
        message.error("Failed to fetch active users data");
      }
      try {
        const response = await apiService.get(GET_MAJOR);
        setMostBusiness(response.data);
      } catch (error) {
        message.error("Failed to fetch business data");
      }
      try {
        const response = await apiService.get(GET_ONLINE);

        setuserOnline(response.data);
      } catch (error) {
        message.error("Failed to fetch business data");
      }
    };
    fetchData();
  }, []);

  const columns = [
    {
      title: "Major",
      dataIndex: "major",
      key: "major",
    },
    {
      title: "Count",
      dataIndex: "count",
      key: "count",
      sorter: (a, b) => a.count - b.count, // Sort by count in descending order
      defaultSortOrder: "descend", // Set default sorting order
    },
  ];
  const columnsOnline = [
    {
      title: "User Name",
      dataIndex: "user_name",
      key: "user_name",
    },
    {
      title: "Device ID",
      dataIndex: "device_id",
      key: "device_id",
      ellipsis: true, // Truncate long text
    },

    {
      title: "Created At",
      dataIndex: "create_at",
      key: "create_at",
      render: (text) => new Date(text).toLocaleString(), // Format date
    },
    {
      title: "Action",
      key: "action",
      render: (record) => (
        <Button type="primary" onClick={() => handleView(record.user_id)}>
          View
        </Button>
      ),
    },
  ];
  return (
    <div
      style={{
        height: "100%",
        padding: "24px",
        background: "#f0f2f5",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <h2>Dashboard</h2>
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          overflowY: "auto",
        }}
      >
        <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
          <Col span={5}>
            <Card
              bordered={false}
              style={{ background: "#ffffff", height: "100%" }}
            >
              <Statistic title="Total Users Registered" value={totalUsers} />
            </Card>
          </Col>

          <Col span={5}>
            <Card
              bordered={false}
              style={{ background: "#ffffff", height: "100%" }}
            >
              <Statistic title="Total Active Users" value={activeUsers} />
            </Card>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Card
              title="5 Most Business"
              bordered={false}
              style={{ background: "#ffffff", height: "100%" }}
            >
              <Table
                columns={columns}
                dataSource={mostBusiness.map((item, index) => ({
                  key: index + 1,
                  ...item,
                }))}
                pagination={false}
              />
            </Card>
          </Col>
          <Col span={16}>
            <Card
              title="5 Most Business"
              bordered={false}
              style={{ background: "#ffffff", height: "100%" }}
            >
              <Table
                columns={columnsOnline}
                dataSource={userOnline.map((item, index) => ({
                  key: index + 1,
                  ...item,
                }))}
                pagination={false}
              />
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Dashboard;
