import axios from "axios";
import store from "../store/store";

const apiService = axios.create({
  baseURL: "https://setting.ecompms.com/api/v1",
  // baseURL: "http://localhost:8000/api/v1",
});

// Add a request interceptor to include the token in the headers
apiService.interceptors.request.use(
  (config) => {
    const token = store.getState().auth.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default apiService;
